// @flow
//Core
import React from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import moment from 'moment';

//Local
import { itemsStyles } from '../styles';
import typography from '../../../../styles/typography.module.scss';
import newsComponentsStyles from '../NewsComponents.module.scss';

const ColumnPostItem = ({
  itemUrl,
  previewImage,
  tags,
  title,
  index,
  date,
}) => {
  const styles = itemsStyles({});

  return (
    <Grid
      xs={12}
      sm={6}
      md={4}
      key={index}
      item
      className={newsComponentsStyles.newsCard}
    >
      <OutboundLink href={itemUrl}>
        <div className={styles.card}>
          <img src={previewImage} alt="" className={styles.cardImg} />
          {tags && (
            <div className={classNames(styles.itemTag, typography.textSmDark)}>
              {tags}
            </div>
          )}
          <div className={styles.newsContent}>
            <h2 className={classNames(styles.title, typography.titleH7Dark)}>
              {title}
            </h2>
            {/* <p className={classNames(styles.subtitle, typography.textSmDark)}>
              {subtitle}
            </p> */}
            <p className={styles.date}>{moment(date).format('YYYY/MM/DD')}</p>
          </div>
        </div>
      </OutboundLink>
    </Grid>
  );
};

export default ColumnPostItem;
