//Core
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash.get';
import { Box, CircularProgress, Container } from '@material-ui/core';
import styles from 'src/components/News/News.module.scss';
//Local
import { Layout } from '../../components';
import * as MediaCenterActions from '../../redux/mediaCenter/actions';
import { SITE_METADATA } from '../../constants';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { PreviewSec, ItemsSec } from '../../components/News/News';
import PressKit from '../../components/News/PressKit/PressKit';

const hashMapping = {
  '#IR': 'IR情報',
  '#kokoku': '電子公告',
};

const News = ({ location }) => {
  const pressPage = useSelector((state) => state.mediaCenter.pressPage);

  const { data, isFetching } = useSelector(
    (state) => state.mediaCenter.pressArticles
  );
  const dispatch = useDispatch();
  const pressArticles = data;
  let selectedTag = null;

  // Did mount
  useEffect(() => {
    dispatch(MediaCenterActions.getPressPageAsync());
    dispatch(MediaCenterActions.getPressArticlesAsync());
  }, [dispatch]);

  const tags = get(pressPage, 'tags', []);

  if (location.hash && tags && pressArticles) {
    let tag = tags.find((t) => t.fields.title === hashMapping[location.hash]);

    if (!tag) {
      const title = decodeURIComponent(location.hash.replace('#', ''));

      tag = tags.find((t) => t.fields.title === title);
    }

    selectedTag = get(tag, 'fields.title');
  }

  return (
    <>
      <Layout
        SEOProps={{
          title: SITE_METADATA.news.title,
          description: SITE_METADATA.news.description,
        }}
      >
        <section className={styles.banner}>
          <h1 className={styles.textBanner}>ニュースルーム</h1>
        </section>
        <Breadcrumb
          customLevels={[
            {
              path: '/',
              title: 'トップ',
            },
            {
              path: '/news/',
              title: 'ニュースルーム',
            },
          ]}
        />

        {isFetching ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py="100px"
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <>
            <PreviewSec
              title={get(pressPage, 'pageTitle')}
              subtitle={get(pressPage, 'pageSubtitle')}
              card={{
                ...get(pressPage, 'primaryArticle.fields', {}),
                imgUrl: get(
                  pressPage,
                  'primaryArticle.fields.previewImage.fields.file.url'
                ),
                url: `/news/article/${get(pressPage, 'primaryArticle.sys.id')}`,
                tags: get(pressPage, 'primaryArticle.fields.tags', []).map(
                  (item) => item.fields.title
                ),
              }}
            />
            <Container>
              <ItemsSec
                title={get(pressPage, 'secondSectionTitle')}
                tags={get(pressPage, 'tags', [])}
                items={pressArticles}
                selectedTag={selectedTag}
              />
            </Container>
          </>
        )}
        <PressKit />
      </Layout>
    </>
  );
};

export default News;

/* eslint-disable jsx-a11y/media-has-caption, react/no-danger, jsx-a11y/no-noninteractive-element-interactions */
// @flow
